import { Typography } from '@mui/material';

const InputError = ({ children }: { children: JSX.Element }) => {
  return (
    <Typography
      mt={1}
      color={(theme) => theme.palette.error.main}
      variant="caption"
    >
      {children}
    </Typography>
  );
};

export default InputError;
