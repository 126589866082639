import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '../icons/close.svg';

interface Props {
  title: string;
  subtitle: string;
}

export default function ContactiDialog({
  title,
  subtitle,
  onClose,
  children,
  ...props
}: DialogProps & Props) {
  return (
    <Dialog
      onClose={onClose}
      maxWidth="lg"
      {...props}
    >
      <DialogContent sx={{ width: '611px' }}>
        <CloseButton onClick={(e) => onClose?.(e, 'backdropClick')}>
          <CloseIcon />
        </CloseButton>
        <Typography
          textAlign="center"
          fontSize={24}
          fontWeight={600}
          sx={{ mt: 3.5, mb: 1.5 }}
        >
          {title}
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={500}
          color="grey.700"
          mb={4.5}
        >
          {subtitle}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  );
}

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '1rem',
  right: '1rem',
}));
