import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import LoadingPage from './components/molecules/LoadingPage';
import ActionsPage from './pages/Actions';
import ContactsPage from './pages/Contacts';

const AgentsPage = lazy(() => import('./pages/Agents'));
const ConversationsPage = lazy(() => import('./pages/Conversations'));
const SettingsPage = lazy(() => import('./pages/Settings'));
const SignupPage = lazy(() => import('./pages/Signup'));
const NumbersPage = lazy(() => import('./pages/Numbers'));
const Flow = lazy(() => import('./pages/Flow'));
const WalletPage = lazy(() => import('./pages/Wallet'));
const NewAssistantPage = lazy(() => import('./pages/NewAssistant'));
const Home = lazy(() => import('./pages/Home'));
const OrderComplete = lazy(() => import('./pages/OrderComplete'));
const Subscriptions = lazy(() => import('./pages/Subscriptions'));
const EditAssistantPage = lazy(() => import('./pages/EditAssistant'));
const LoginPage = lazy(() => import('./pages/Login'));
const OverviewPage = lazy(() => import('./pages/Overview'));

const router = createBrowserRouter([
  {
    path: '/signin',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SignupPage />,
  },
  {
    path: '/overview',
    element: <OverviewPage />,
  },
  {
    path: '/assistants',
    element: <AgentsPage />,
  },
  {
    path: '/assistants/new',
    element: <NewAssistantPage />,
  },
  {
    path: '/assistants/:id',
    element: <EditAssistantPage />,
  },
  {
    path: '/conversations',
    element: <ConversationsPage />,
  },
  {
    path: '/actions',
    element: <ActionsPage />,
  },
  {
    path: '/numbers/:kind?',
    element: <NumbersPage />,
  },
  {
    path: '/workflows',
    element: <Flow />,
  },
  {
    path: '/contacts',
    element: <ContactsPage />,
  },
  {
    path: '/settings',
    element: <SettingsPage />,
  },
  {
    path: '/wallet',
    element: <WalletPage />,
  },
  {
    path: '/wallet/subscriptions',
    element: <Subscriptions />,
  },
  {
    path: '/order/complete',
    element: <OrderComplete />,
  },
  {
    path: '/',
    element: <Home />,
  },
]);

export default function Router() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
