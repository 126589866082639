import { FormControl, FormLabel, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import QuestionIcon from './questionIcon.svg';

interface Props {
  title: string;
  subtitle?: string;
  children: ReactNode;
  info?: string;
  sx?: SxProps;
  optional?: boolean;
}

export default function FieldWrapper({
  title,
  subtitle,
  info,
  children,
  optional,
  sx,
}: Props) {
  return (
    <FormControl
      sx={{ mb: 2, ...sx }}
      fullWidth
    >
      <FormLabel
        sx={{ fontWeight: 600, fontSize: 14, lineHeight: '23.11px', mb: 0.5 }}
      >
        {title}
        {optional && (
          <Typography
            fontSize={10}
            fontWeight={400}
            color="grey.700"
            display="inline"
            ml={0.5}
          >
            (Optional)
          </Typography>
        )}
        {info && (
          <a
            title={info}
            style={{ marginLeft: '.5rem' }}
          >
            <QuestionIcon />
          </a>
        )}
      </FormLabel>
      <Typography
        fontSize={12}
        color="grey.700"
        fontWeight={500}
      >
        {subtitle}
      </Typography>
      {children}
    </FormControl>
  );
}
