import { Box } from '@mui/material';
import ContactiDialog from '../../components/atoms/ContactiDialog';
import ContactForm, { Contact } from '../../components/organs/ContactForm';

interface Props {
  initialValues: Contact;
  open: boolean;
  onClose: () => void;
  onSave: (d: Contact) => void;
}

export default function EditContactDialog({
  initialValues,
  onSave,
  ...props
}: Props) {
  return (
    <ContactiDialog
      title="Add a new contact"
      subtitle="Enter contact information below."
      {...props}
    >
      <Box
        width={460}
        mx="auto"
      >
        <ContactForm
          initialValues={initialValues}
          handleSubmit={onSave}
        />
      </Box>
    </ContactiDialog>
  );
}
