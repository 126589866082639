import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import SectionHeader from '../../components/atoms/SectionHeader';
import Layout from '../../components/Layout';
import NavBreadcrumb from '../../components/molecules/NavBreadcrumb';
import ActionIcon from './action.svg';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import NewActionDialog from './NewActionDialog';
import useFetchActions, { CustomAction } from '../../hooks/useFetchActions';
import ActionsTable from './ActionsTable';
import * as Sentry from '@sentry/react';
import useSnackbar from '../../components/Snackbar';
import httpAgent from '../../httpAgent';

export default function ActionsPage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data: actions, refetch, isLoading } = useFetchActions();

  const showSnackbar = useSnackbar();
  const createAction = async (data: CustomAction) => {
    try {
      const response = await httpAgent.post(
        'actions/',
        JSON.stringify({ action: 'API', ...data })
      );
      if (response.status !== 201) {
        showSnackbar('Something went wrong', 'error');
        return;
      }
      setCreateModalOpen(false);
      refetch();
      showSnackbar('Custom action created.');
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Layout>
      <NavBreadcrumb primary="Custom Actions" />
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <SectionHeader
          Icon={ActionIcon}
          title="Create New Custom Action"
          subtitle="Create your own customized actions"
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => setCreateModalOpen(true)}
        >
          <AddIcon sx={{ mr: 1 }} /> Create New Action
        </Button>
      </Box>
      <Paper
        elevation={0}
        sx={{
          mt: 4,
          height: 'calc(100vh - 12rem)',
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress size={40} />
          </Box>
        ) : actions?.length === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color="grey.700"
            >
              No records to show
            </Typography>
          </Box>
        ) : (
          <ActionsTable data={actions!} />
        )}
      </Paper>
      <NewActionDialog
        initialValues={{
          url: '',
          method: 'POST',
          token: '',
          params: [],
          name: '',
          description: '',
          user_message: '',
        }}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSave={createAction}
      />
    </Layout>
  );
}
