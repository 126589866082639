import axios, { Axios } from 'axios';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const apiDomain = import.meta.env.DEV ? '' : 'https://api.contacti.ai';

const httpAgent = new Axios({
  baseURL: `${apiDomain}/api/`,
  headers: defaultHeaders,
});

httpAgent.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

httpAgent.interceptors.response.use(async (res) => {
  if (res.status === 401) {
    const data = JSON.parse(res.data);
    if (data.code === 'token_not_valid') {
      try {
        const refreshResponse = await refreshToken();
        if (!('access' in refreshResponse)) {
          logoutUser();
          return Promise.reject(res);
        }
        const { access } = refreshResponse;
        localStorage.setItem('access_token', access);

        const newConfig = { ...res.config };
        newConfig.headers.Authorization = `Bearer ${access}`;

        return axios(newConfig);
      } catch (e) {
        logoutUser();
        return Promise.reject(e);
      }
    }
  }
  return res;
});

function logoutUser() {
  localStorage.clear();
  window.location.href = '/signin';
}

async function refreshToken() {
  const con = await fetch(`${apiDomain}/api/token/refresh/`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({
      refresh: localStorage.getItem('refresh_token'),
    }),
  });
  return await con.json();
}

export default httpAgent;
