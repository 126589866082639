import { useState } from 'react';
import ContactiDialog from '../../../components/atoms/ContactiDialog';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import ServerUrlStep from './ServerUrlStep';
import SchemaStep from './SchemaStep';
import PurposeStep from './PurposeStep';
import { CustomAction } from '../../../hooks/useFetchActions';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues: CustomAction;
  onSave: (data: CustomAction) => void;
}

const steps = [
  {
    Component: ServerUrlStep,
    title: 'Server URL',
    subtitle: 'Create a custom function and connect to any backend',
  },
  {
    Component: SchemaStep,
    title: 'Build your schema',
    subtitle: 'Define your schema which the LLM should generate.',
  },
  {
    Component: PurposeStep,
    title: 'Purpose of the tool',
    subtitle: 'Add Details to your tool which will be used by LLM',
  },
];

export default function NewActionDialog({
  open,
  onClose,
  initialValues,
  onSave,
}: Props) {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<CustomAction>(initialValues);

  const onNext = async (newVals: typeof values) => {
    const mergedValues = {
      ...values,
      ...newVals,
    };
    setValues(mergedValues);

    if (step === 2) {
      await onSave(mergedValues);
      setStep(0);

      return;
    }

    setStep((p) => p + 1);
  };

  const { Component, title, subtitle } = steps[step];
  return (
    <ContactiDialog
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={onClose}
    >
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{ mb: 4 }}
      >
        {new Array(3).fill(0).map((_, index) => (
          <Step key={index}>
            <StepLabel>Step {index + 1}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ width: '470px', mx: 'auto' }}>
        <Component
          initialValues={values}
          onNext={onNext}
          onPrev={() => setStep((p) => p - 1)}
        />
      </Box>
    </ContactiDialog>
  );
}
