import { useQuery } from "@tanstack/react-query";
import httpAgent from "../httpAgent";

async function fetchActions() {
  const response = await httpAgent.get('actions/')
  return JSON.parse(response.data)
}

export interface IField {
  id?: number;
  type:
  | 'array_api_parameter'
  | 'string_api_parameter'
  | 'integer_api_parameter'
  | 'number_api_parameter'
  | 'boolean_api_parameter'
  | 'phone_number_api_parameter'
  | 'date_api_parameter'
  | 'datetime_api_parameter';
  name: string;
  description: string;
}

export interface CustomAction {
  id?: number
  name: string
  updated_at?: string
  url: string
  method: string
  params: IField[]
  user_message: string
  description: string
  token: string

}

export default function useFetchActions() {
  return useQuery<CustomAction[]>({
    queryKey: ['actions'],
    queryFn: fetchActions
  })
}