import {
  Alert,
  Snackbar as MuiSnackbar,
  Slide,
  SlideProps,
} from '@mui/material';
import { createContext, ReactNode, useContext, useState } from 'react';

const SnackbarContext = createContext(
  (_: string, _severity?: Data['severity']) => {}
);

interface Data {
  severity: 'success' | 'error';
  text: string | null;
}

const initialData: Data = { severity: 'success', text: null };

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<Data>(initialData);

  const show = (text: string, severity: Data['severity'] = 'success') => {
    setData({ text, severity });
  };

  return (
    <SnackbarContext.Provider value={show}>
      {children}
      <MuiSnackbar
        open={!!data.text}
        TransitionComponent={(props: SlideProps) => (
          <Slide
            {...props}
            direction="up"
          />
        )}
        autoHideDuration={5000}
        onClose={() => setData(initialData)}
      >
        <Alert
          severity={data.severity}
          variant="filled"
        >
          {data.text}
        </Alert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  );
}

export default function useSnackbar() {
  return useContext(SnackbarContext);
}
