import { Breadcrumbs, Typography } from '@mui/material';

interface Props {
  parent?: string;
  primary: string;
}

export default function NavBreadcrumb({ parent, primary }: Props) {
  return (
    <Breadcrumbs sx={{ mb: 4 }}>
      <Typography
        fontWeight={600}
        color="GrayText"
      >
        Dashboard
      </Typography>
      {parent && (
        <Typography
          fontWeight={600}
          color="GrayText"
        >
          {parent}
        </Typography>
      )}
      <Typography
        fontWeight={600}
        color="text.primary"
      >
        {primary}
      </Typography>
    </Breadcrumbs>
  );
}
