import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import ContactiDialog from '../../components/atoms/ContactiDialog';
import ContactForm from '../../components/organs/ContactForm';
import httpAgent from '../../httpAgent';
import useSnackbar from '../../components/Snackbar';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  open: boolean;
  onClose: () => void;
}

const initialValues = {
  name: '',
  phone: '',
  country_code: '1',
};

export default function CreateContactDialog(props: Props) {
  const showSnackbar = useSnackbar();
  const qc = useQueryClient();
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const response = await httpAgent.post(
        'contacts/',
        JSON.stringify(values)
      );
      if (response.status !== 201) {
        showSnackbar('Something went wrong', 'error');
        return;
      }
      props.onClose();
      qc.invalidateQueries({ queryKey: ['contacts'] });
      showSnackbar('Custom action updated.');
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <ContactiDialog
      title="Add a new contact"
      subtitle="Enter contact information below."
      {...props}
    >
      <Box
        width={460}
        mx="auto"
      >
        <ContactForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      </Box>
    </ContactiDialog>
  );
}
