import { SxProps, Theme, Typography } from '@mui/material';
import { MouseEvent } from 'react';

interface Data {
  title: string;
  value: string;
}

type Props = Data & {
  active?: boolean;
  onClick: (d: Data) => void;
};

export default function SimpleItem({
  active = false,
  onClick,
  ...data
}: Props) {
  const handleClick = (e: MouseEvent) => {
    onClick?.(data);
    e.stopPropagation();
  };

  return (
    <Typography
      sx={sxProps(active)}
      fontSize={12}
      fontWeight={500}
      onClick={handleClick}
    >
      {data.title}
    </Typography>
  );
}

const sxProps = (active: boolean) =>
  ({
    px: '1rem',
    py: '6px',
    cursor: 'pointer',
    ...(!active && {
      '&:hover': {
        background: (theme) => theme.palette.primary.lightest,
      },
    }),
  }) as SxProps<Theme>;
