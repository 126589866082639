import { useQuery } from "@tanstack/react-query";
import httpAgent from "../httpAgent";
import { Contact } from "../components/organs/ContactForm";

async function fetchContacts() {
  const response = await httpAgent.get('contacts/')
  return JSON.parse(response.data)
}

export default function useFetchContacts() {
  return useQuery<Contact[]>({
    queryKey: ['contacts'],
    queryFn: fetchContacts
  })
}