import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ISectionHeaderProps {
  Icon?: FC | string;
  title: string;
  subtitle?: ReactNode;
}

export default function SectionHeader({
  Icon,
  title,
  subtitle,
}: ISectionHeaderProps) {
  const content = (
    <Box>
      <Typography fontWeight={600}>{title}</Typography>
      {subtitle && (
        <Typography
          mt={1}
          color={(theme) => theme.palette.grey[700]}
          fontWeight={500}
          fontSize={12}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
  if (!Icon) return content;

  return (
    <Box
      display="flex"
      gap={1}
    >
      <Icon />
      {content}
    </Box>
  );
}
