import { ReactNode, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldWrapper from '../../../components/molecules/FieldWrapper';
import InputError from '../../../components/atoms/InputError';
import ItemSelector from '../../../components/atoms/ItemSelector';
import OpenIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Remove';
import { CustomAction, IField } from '../../../hooks/useFetchActions';

interface SchemaProps {
  initialValues: CustomAction;
  onNext: (e: any) => void;
  onPrev: () => void;
}

const items = [
  { title: 'Array', value: 'array_api_parameter' },
  { title: 'String', value: 'string_api_parameter' },
  { title: 'Integer', value: 'integer_api_parameter' },
  { title: 'Number', value: 'number_api_parameter' },
  { title: 'Boolean', value: 'boolean_api_parameter' },
  { title: 'Phone Number', value: 'phone_number_api_parameter' },
  { title: 'Date', value: 'date_api_parameter' },
  { title: 'Datetime', value: 'datetime_api_parameter' },
];

export default function SchemaStep({
  initialValues,
  onNext,
  onPrev,
}: SchemaProps) {
  const [editorOpen, setEditorOpen] = useState(
    initialValues.params?.length > 0 ? false : true
  );
  const [fields, setFields] = useState<IField[]>(initialValues.params || []);

  const onAdd = (values: IField, helpers: FormikHelpers<IField>) => {
    setFields((prev) => [...prev, { ...values, id: prev.length + 1 }]);
    helpers.resetForm();
    setEditorOpen(false);
  };

  const onEdit = (values: IField) => {
    setFields((prev) => {
      const index = prev.findIndex((f) => f.id === values.id);
      const clone = [...prev];
      clone[index] = values;
      return clone;
    });
  };

  const onDelete = (id: number) => {
    setFields((prev) => prev.filter((f) => f.id !== id));
  };

  return (
    <>
      {fields.map((field) => (
        <Field
          key={field.name}
          data={field}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
      {editorOpen ? (
        <>
          <DividerWithTitle>Add Property</DividerWithTitle>
          <FieldEditor
            initialValue={{
              name: '',
              description: '',
              type: 'string_api_parameter',
            }}
            onSubmit={onAdd}
            onDelete={() => setEditorOpen(false)}
          />
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Button
            onClick={() => setEditorOpen(true)}
            sx={{ mt: 2, display: 'flex', gap: '.25rem', alignItems: 'center' }}
          >
            <OpenIcon />
            Add new property
          </Button>
        </Box>
      )}
      <Box
        display="flex"
        gap={2}
        sx={{ mt: 4, mb: 5.5 }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={onPrev}
          sx={{
            py: 1.5,
            backgroundColor: (theme) => theme.palette.grey[300],
            color: (theme) => theme.palette.grey[700],
            '&:hover': {
              backgroundColor: (theme) => theme.palette.grey[500],
            },
          }}
        >
          Previous
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onNext({ params: fields })}
          sx={{ py: 1.5 }}
        >
          Next
        </Button>
      </Box>
    </>
  );
}

interface FieldProps {
  data: IField;
  onEdit: (d: IField) => void;
  onDelete: (id: number) => void;
}

function Field({ data, onEdit, onDelete }: FieldProps) {
  const [open, setOpen] = useState(false);

  const onSubmit = (d: IField) => {
    setOpen(false);
    onEdit(d);
  };

  return (
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpen((p) => !p)}
        py="6px"
        sx={{ cursor: 'pointer', pb: open ? 2 : '6px' }}
      >
        <Typography
          fontSize={14}
          fontWeight={600}
          lineHeight="21px"
          color="primary"
        >
          {data.name}
        </Typography>
        {open ? (
          <CloseIcon
            fontSize="small"
            color="primary"
          />
        ) : (
          <OpenIcon
            fontSize="small"
            color="primary"
          />
        )}
      </Box>
      {open && (
        <FieldEditor
          initialValue={data}
          onSubmit={onSubmit}
          onDelete={() => onDelete(data.id!)}
        />
      )}
    </Box>
  );
}

const schema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required.')
    .max(255, 'Property name cannot be more than 255 characters.'),
  description: Yup.string()
    .required('Property description is required')
    .max(1000, 'Property description cannot be more than 1000 characters.'),
});

interface FieldEditorProps {
  initialValue: IField;
  onSubmit: (d: IField, bag: FormikHelpers<IField>) => void;
  onDelete: () => void;
}

function FieldEditor({ initialValue, onSubmit, onDelete }: FieldEditorProps) {
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <FieldWrapper title="Property Name">
            <TextField
              placeholder="Define your property name"
              fullWidth
              InputProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '50px',
                  px: 2,
                },
              }}
              {...props.getFieldProps('name')}
              error={props.touched.name && Boolean(props.errors.name)}
            />
            <InputError>
              <ErrorMessage name="name" />
            </InputError>
          </FieldWrapper>
          <FieldWrapper title="Property Type">
            <ItemSelector
              items={items}
              {...props.getFieldProps('type')}
            />
          </FieldWrapper>
          <FieldWrapper title="Property Description">
            <TextField
              fullWidth
              multiline
              placeholder="Describe the property, its purpose, its use, etc."
              sx={{ mt: 0.5, borderRadius: '18px' }}
              inputProps={{ sx: { fontSize: '12px', px: 2 } }}
              {...props.getFieldProps('description')}
              error={
                props.touched.description && Boolean(props.errors.description)
              }
            />

            <InputError>
              <ErrorMessage name="description" />
            </InputError>
          </FieldWrapper>
          <Box
            display="flex"
            gap={2}
            mt={3}
            mb={3}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                props.resetForm();
                onDelete();
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.grey[300],
                color: (theme) => theme.palette.grey[700],
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.grey[500],
                },
              }}
            >
              Delete
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="success"
              sx={{ color: 'common.white' }}
              type="submit"
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

function DividerWithTitle({ children }: { children: ReactNode }) {
  return (
    <Box
      position="relative"
      height="14px"
      display="flex"
      alignItems="center"
      mt={2}
      mb={3}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}` }}
    >
      <Typography
        fontSize={14}
        fontWeight={600}
        sx={{
          position: 'absolute',
          zIndex: 2,
          top: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          background: '#fff',
          px: 2,
          pt: 0.5,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}
