import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, styled, SxProps } from '@mui/material';
import SimpleItem from './SimpleItem';
import ArrowDownIcon from './arrow-down.svg';
import { createPortal } from 'react-dom';

interface Item {
  title: string;
  value: string | number;
}

export interface SelectEvent {
  type: 'change';
  target: {
    name: string;
    value: string | number;
  };
}

interface IItemSelectorProps {
  value: string;
  items: Item[] | undefined;
  sx?: SxProps;
  itemRenderer?: (value: any) => ReactNode;
  onChange: (event: SelectEvent) => void;
  disabled?: boolean;
  name: string;
  error?: boolean;
}

export default function ItemSelector({
  value,
  items,
  sx,
  onChange,
  disabled = false,
  name,
  error,
  itemRenderer = (props) => (
    <SimpleItem
      key={props.value}
      {...props}
    />
  ),
}: IItemSelectorProps) {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (disabled) return;
    setOpen(true);
  };

  const onItemClick = (value: Item) => {
    onChange({ type: 'change', target: { name, value: value.value } });
    setOpen(false);
  };

  const [position, setPosition] = useState({} as DOMRect);
  useEffect(() => {
    setPosition(ref.current?.getClientRects()[0] as DOMRect);
  }, []);

  const valueObj = items?.find((item) => item.value === value) || {};

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        ref={ref}
        onClick={onClick}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          border: (theme) =>
            open
              ? `2px solid ${theme.palette.primary.main}`
              : error
                ? `1px solid ${theme.palette.error.main}`
                : `1px solid ${theme.palette.grey[500]}`,
          borderRadius: '50px',
          pl: open ? '11px' : '12px',
          pr: open ? '23px' : '24px',
          py: open ? '7px' : '8px',
          cursor: 'pointer',
          height: '3rem',
          position: 'relative',
          ...(disabled && {
            background: (theme) => theme.palette.grey[100],
            cursor: 'not-allowed',
          }),
          ...sx,
        }}
      >
        {itemRenderer({ ...valueObj, onClick, active: true })}
        <ArrowDownIcon />
        <Menu
          position={position}
          open={open}
          onClose={() => setOpen(false)}
        >
          {items?.map((item) =>
            itemRenderer({ ...item, onClick: onItemClick })
          )}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
}

interface MenuProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  position: DOMRect;
}

function Menu({ open, position, children }: MenuProps) {
  return createPortal(
    <MenuBox
      sx={{
        display: open ? 'block' : 'none',
        top: position.y + position.height + 10,
        left: position.x,
        width: position.width,
      }}
    >
      {children}
    </MenuBox>,
    document.body
  );
}

const MenuBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  // top: 'calc(100% + 10px)',
  // left: 0,
  // right: 0,
  zIndex: theme.zIndex.tooltip,
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[500]}`,
  overflow: 'hidden',
  borderRadius: '10px',
  '& > *': {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));
