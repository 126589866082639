import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import useSnackbar from '../../components/Snackbar';
import httpAgent from '../../httpAgent';

interface IProps {
  open: boolean;
  id: number;
  onClose: () => void;
}

export default function ConfirmDeleteDialog({ id, open, onClose }: IProps) {
  const [loading, setLoading] = useState(false);
  const showSnackbar = useSnackbar();

  const qc = useQueryClient();
  const handleDeleteAction = async () => {
    try {
      setLoading(true);
      await httpAgent.delete(`actions/${id}/`);
      showSnackbar('Custom action deleted successfully.');
      qc.invalidateQueries({ queryKey: ['actions'] });
      onClose();
    } catch (error) {
      Sentry.captureException(error, { tags: { backendError: true } });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
    >
      <DialogTitle>Delete the action?</DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <DialogContentText>
          <Box
            component="span"
            color={(theme) => theme.palette.warning.main}
          >
            Attention:
          </Box>{' '}
          Your custom action will be permanently removed and will no longer be
          accessible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={handleDeleteAction}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
