import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

const grey = {
  700: '#91979d',
  500: '#bcc2ca',
  300: '#eeeeee',
  100: '#fafafa',
};

const pantone = {
  main: '#4f43db',
  light: '#b4b0f8',
  lightest: '#dedcff',
};

const darkTheme = createTheme({
  palette: {
    primary: pantone,
    success: {
      main: '#2dbd85',
    },
    error: {
      main: '#e33c54',
    },
    grey: grey,
    black: { main: '#000' },
    white: { main: '#fff' },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontFamily: '"Poppins", sans serif',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 21,
      },
      styleOverrides: {
        elevation21: {
          boxShadow: `0px 3.56px 21.33px 0px rgba(9, 7, 37, 0.07)`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderRadius: '40px',
          color: 'black',
          borderColor: 'black',
        },
        contained: {
          borderRadius: '40px',
          boxShadow: 'none',
          borderWidth: '0px !important',
        },
        sizeSmall: {
          fontSize: '9.66px',
          padding: '9px 16px',
          fontWeight: 700,
          border: `1px solid ${grey[500]}`,
          height: '33px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
        multiline: {
          borderRadius: '18px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 3.56px 21.33px 0px #09072511',
          borderRadius: '10px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: grey[700],
          fontSize: 12,
          fontWeight: 500,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          '&.Mui-selected': {
            fontWeight: 600,
            color: pantone.main,
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontSize: '10px',
          fontWeight: 500,
          color: grey[500],
        },
        markLabelActive: {
          background: pantone.main,
          color: '#fff',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    },
  },
});

interface IProps {
  children: ReactNode;
}

export default function Theme({ children }: IProps) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

declare module '@mui/material/styles' {
  export interface PaletteOptions {
    black: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
  }
  export interface PaletteColor {
    lightest?: string;
  }

  export interface SimplePaletteColorOptions {
    lightest?: string;
  }
  export interface ButtonPropsColorOverrides {
    color: 'black';
  }
}
