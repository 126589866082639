import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Layout from '../../components/Layout';
import NavBreadcrumb from '../../components/molecules/NavBreadcrumb';
import SectionHeader from '../../components/atoms/SectionHeader';
import ContactsIcon from './contacts.svg';
import AddIcon from '@mui/icons-material/Add';
import { ChangeEvent, useMemo, useState } from 'react';
import CreateContactDialog from './CreateContactDialog';
import useFetchContacts from '../../hooks/useFetchContacts';
import ContactsTable from './ContactsTable';
import { Search } from '@mui/icons-material';

const PAGE_SIZE = 20;

export default function ContactsPage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { isLoading, data } = useFetchContacts();

  const [query, setQuery] = useState('');
  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setPage(1);
  };
  const queryResults = useMemo(() => {
    if (!data) return [];
    return data.filter(
      (item) => item.name.startsWith(query) || item.phone.startsWith(query)
    );
  }, [data, query]);

  const [page, setPage] = useState(1);

  const pageData = useMemo(() => {
    if (!queryResults) return [];

    let results = [];
    for (
      let i = (page - 1) * PAGE_SIZE;
      i < page * PAGE_SIZE && queryResults[i];
      i++
    ) {
      results.push(queryResults[i]);
    }
    return results;
  }, [page, queryResults]);

  return (
    <Layout>
      <NavBreadcrumb primary="Contacts" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionHeader
          title="Contacts"
          subtitle="Your contacts will appear here"
          Icon={ContactsIcon}
        />
        <TextField
          sx={{ ml: 'auto', mr: 2 }}
          inputProps={{
            sx: {
              fontSize: '12px',
              py: 1,
            },
          }}
          InputProps={{
            startAdornment: <Search color="disabled" />,
          }}
          placeholder="Search"
          value={query}
          onChange={onQueryChange}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => setCreateModalOpen(true)}
        >
          <AddIcon sx={{ mr: 1 }} /> Create New Contact
        </Button>
      </Box>
      <Paper
        sx={{
          mt: 4,
          minHeight: 'calc(100vh - 12rem)',
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 12rem)"
          >
            <CircularProgress size={40} />
          </Box>
        ) : queryResults.length === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 12rem)"
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color="grey.700"
            >
              No records to show
            </Typography>
          </Box>
        ) : (
          <ContactsTable data={pageData} />
        )}
      </Paper>
      {!isLoading && queryResults.length > PAGE_SIZE && (
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            count={Math.ceil((queryResults?.length || 0) / PAGE_SIZE)}
            page={page}
            onChange={(_e: ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
            size="large"
            shape="rounded"
            color="primary"
            siblingCount={1}
          />
        </Box>
      )}
      <CreateContactDialog
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </Layout>
  );
}
